import { render, staticRenderFns } from "./Contact.vue?vue&type=template&id=49dfe819"
import script from "./Contact.vue?vue&type=script&lang=js"
export * from "./Contact.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@3.2.5_vue-temp_n4gdrmfirpmgaryqotnkfbokze/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormGenerator: require('/src/components/form/generator/index.vue').default,ModalComponent: require('/src/components/ModalComponent.vue').default})
