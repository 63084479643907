
export default {
  name: 'ModalComponent',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 420,
    },
    wrapperClass: {
      type: String,
      default: 'rounded-lg',
    },
    backgroundClass: {
      type: String,
      default: 'bg-black opacity-25',
    },
    bodyClass: {
      type: String,
      default: 'p-6 pt-0 modal-body',
    },
    disableOnClickOutside: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(newVal) {
      if (newVal === true) {
        document.body.classList.add('modal-open')
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.calculatePosition()
          })
        })
      } else {
        document.body.classList.remove('modal-open')
      }
    },
  },
  mounted() {
    document.body.classList.add('overflow-hidden')
    document.addEventListener('keydown', this.closeWithEscape)
  },
  destroyed() {
    document.body.classList.remove('overflow-hidden')
    document.removeEventListener('keydown', this.closeWithEscape)
  },
  methods: {
    closeWithEscape(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.$emit('close')
      }
    },
    calculatePosition() {
      try {
        const { offsetLeft, clientWidth, offsetTop } = this.$refs.modalRef
        this.$emit('position', { offsetLeft, clientWidth, offsetTop })
      } catch {
        this.$emit('position', { offsetLeft: 0, clientWidth: 0, offsetTop: 0 })
      }
    },
  },
}
