
import { mapMutations } from 'vuex'
export default {
  name: 'Contact',
  data() {
    return {
      status: 'finished',
      reference: undefined,
    }
  },
  computed: {
    // structure() {
    //   return this.$store.state['dynamic-contacts'].structure
    // },
    show() {
      return this.$store.state['dynamic-contacts'].show
    },
    // loading() {
    //   return this.$store.state['dynamic-contacts'].loading
    // },
    // errors() {
    //   return this.$store.state['dynamic-contacts'].errors
    // },
    preselects() {
      const form = this.$store.state['dynamic-contacts']
      let preselects = form.preselects || {}
      if (typeof preselects?.company_division === 'undefined') {
        preselects = {
          ...preselects,
          company_division: undefined,
        }
      }

      return Object.entries(preselects).map((item) => {
        return {
          name: item[0],
          change: (input) => (input.attributes.value = item[1]),
        }
      })
    },
  },
  watch: {
    show(newValue) {
      if (!newValue) {
        // this.setPreselects({}) // reset predefined
        document.body.classList.remove('overflow-hidden')
      }
    },
  },
  mounted() {
    document.body.classList.remove('overflow-hidden')
  },
  methods: {
    ...mapMutations({
      setShow: 'dynamic-contacts/setShow',
      setPreselects: 'dynamic-contacts/setPreselects',
    }),
    // setReference() {
    //   this.reference = this.$refs.form.$children

    //   this.focusForm()
    // },
    focusForm() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs?.form?.$el?.focus()
        })
      })
    },
  },
}
